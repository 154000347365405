import { ToastPosition } from '@chakra-ui/react';

export const toastOptions = {
    position: 'top' as ToastPosition,
    variant: 'left-accent',
    isClosable: true,
    duration: 2500,
};

export const HOST_API = process.env.NEXT_PUBLIC_HOST_URL;
