// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react';

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
    primary: {
        dark: '#291a72',
        light: '#4c50ea',
    },
    tertiary: {
        dark: '#1a3f3b',
        light: '#34c1a6',
    },
    secondary: '#7f4eec',
    titleText: '#231a2c',
    subText: '#9ca3af',
    mainText: '#374151',
    sidebarBg: '#120c20',
    sidebarText: '#A6B1BA',
    linkActive: '#333B48',
    border: '#e5e7eb',
    placeHolderIconColor: '#6B7280',
    grayText: '#1a202c',
    redIndigo: '#EEF2FF',
    headerBg: '#fafaff',
    lightBlueBg: '#EEF3FF',
    loaderBg: 'rgba(0,0,0,0.02)',
    starRating: '#ECC94B',
    calenderViewBg: '#f3f4f6',
    faqsSubText: '#A0AEC0',
    popoverHover: '#F4F4FE',
    shadowSlate: '#4B5563',
    white: '#FFFFFF',
    activeText: '#38A169',
};

const fonts = {
    heading: 'Fira Sans',
    body: 'Rubik',
};

export const theme = extendTheme({ colors, fonts });
