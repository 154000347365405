/* eslint-disable react-hooks/exhaustive-deps */

'use client';

import React, { useCallback, useEffect } from 'react';

import { useToast } from '@chakra-ui/react';

import useApiSelector from '@hooks/useApiSelector';

interface ToastContainerProps {
    children: React.ReactNode;
}

const ToastContainer = ({ children }: ToastContainerProps) => {
    const toast = useToast();
    const { variant, description, showToast } = useApiSelector();

    const displayToast = useCallback(() => {
        if (description) {
            toast({ status: variant, description });
        }
    }, [description, toast, variant]);

    useEffect(() => {
        displayToast();
    }, [showToast]);

    return <>{children}</>;
};

export default ToastContainer;
